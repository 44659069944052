.Attentionbar {
  position: relative;
  padding: 0.5rem;
  font-size: 1rem;
  box-sizing: border-box;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Attentionbar a {
  text-decoration: underline;
}

.Attentionbar * {
  color: inherit;
}
