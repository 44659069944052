.WhatsOnWidget {
  padding: 20px 200px;
}
.WhatsOnWidget * {
  color: var(--color-ascent);
}
select {
  padding: 5px 10px;
  border: 2.5px solid var(--color-ascent);
  border-radius: 0;
}
.WeeklyEvent {
  width: 315px;
  height: 100px;
  border: 3px solid var(--color-ascent);
  padding: 20px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.WeeklyEvent h2 {
  width: 100px;
  line-height: 1rem;
}
.WeeklyEvent * {
  font-size: 16px;
}
.CourseHolder {
  display: flex;
  flex-direction: row;
  padding: 20px 0;
  row-gap: 20px;
  column-gap: 40px;
  flex-wrap: wrap;
}
.Controls {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
@media only screen and (max-width: 1025px) {
  .Controls {
    flex-direction: column;
    align-items: center;
  }
  .WhatsOnWidget {
    padding: 20px 1rem;
  }
  .CourseHolder {
    flex-direction: column;
    padding: 20px 0;
    row-gap: 20px;
    column-gap: 40px;
    flex-wrap: wrap;
    align-items: center;
  }
}
