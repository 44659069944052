@import url('../../vars.css');
.Aboutclimbing {
  background-color: var(--color-light);
}

.Slogan {
  font-size: 2rem;
  width: 100%;
  line-height: 5rem;
  text-align: center;
  text-shadow: 0px 2px 5px #00000043;
}
.Slogan > * {
  font-weight: 500;
}

a {
  text-decoration: none;
}

.LandingContent {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 100%;
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
  align-items: center;
}

.Content {
  padding: 1.5rem 1rem;

  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.ContentWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Extra {
  grid-area: extra;
  font-size: 15px;
}

.Intro {
  grid-area: intro;
  font-size: 15px;
}
.Bouldering {
  grid-area: bouldering;
}
.Ropes {
  grid-area: ropes;
}
.Links {
  grid-area: links;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.Links a {
  /* width: 100%; */
}

.Panel {
  background-color: white;
  padding: 1rem;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}
.Panel h2 {
  font-size: 43px;
  line-height: 43px;
}
.Panel img {
  border-radius: 30px;
  /* width: 100%; */
  max-height: 250px;
}
.Panel p {
  font-size: 15px;
}
/* Desktop */
@media only screen and (min-width: 1025px) {
  .Content {
    padding: 1.5rem 200px;
  }
  .Panel {
    padding: 30px;
  }
  .Panel p {
    font-size: 22px;
  }
  .Intro {
    font-size: 25px;
  }
  .Extra {
    font-size: 25px;
  }
  .LandingContent {
    padding: 0 10rem;
  }
  .Links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 50px;
  }
  .Slogan {
    font-size: 2.5rem;
  }
  .LandingContent {
    padding: 0 10rem;
  }
}
