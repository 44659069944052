.PrivacyPolicy {
  padding: 100px 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* .PrivacyPolicyContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
} */
.PrivacyPolicyContent h1 {
  font-size: 3rem;
  color: var(--color-secondary);
}
.PrivacyPolicyContent h2 {
  color: var(--color-ascent);
  font-size: 2rem;
}
.PrivacyPolicyContent h3 {
  color: var(--color-primary);
}
.PrivacyPolicyContent section {
  margin-bottom: 20px;
}
.PrivacyPolicy small {
  color: var(--color-secondary);
  font-size: 0.8rem;
  margin-top: 20px;
}

@media only screen and (max-width: 1025px) {
  .PrivacyPolicy {
    padding: 2rem;
  }
}
