@import '../../vars.css';

.Button {
  all: unset;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 30px;
  cursor: pointer;
  text-align: center;
  align-items: center;
  line-height: normal;
}

.Button p {
  color: white;
  font-weight: 500 !important;
}

.Arrow {
  color: white;
  margin-left: 1rem;
  display: grid;
  place-content: center;
  place-items: center;
}
.Primary {
  background-color: var(--color-primary);
}
.Secondary {
  background-color: var(--color-ascent);
}

.sm .Arrow {
  margin-left: 1rem;
}

.sm {
  padding: 0.25rem 1rem;
  font-size: 0.75rem;
}
.md {
  padding: 0.5rem 0.75rem;
  font-size: 0.8rem;
}
.lg {
  padding: 0.75rem 3rem;
  font-size: 1.25rem;
}

.fll {
  font-size: 1.25rem;
  padding: 0.75rem 3rem;
  width: 100%;
  box-sizing: border-box;
}

@media only screen and (min-width: 1025px) {
  .Arrow {
    margin-left: 1rem;
  }
  .sm {
    padding: 0.25rem 2rem;
    font-size: 1rem;
  }
  .md {
    padding: 0.5rem 3rem;
    font-size: 1.25rem;
  }
  .lg {
    padding: 0.5rem 3rem;
    font-size: 1.5rem;
    border-radius: 2rem;
  }
}
