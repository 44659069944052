.Stepsystem {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.Navigation {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* background-color: #f0f0f0; */
}

.Step {
  text-align: center;
  cursor: pointer;
  padding: 10px;
  transition: border-bottom 0.1s ease;
  width: 100%;
  border-bottom: 2px solid #00000000;
}

.Step:hover {
  border-bottom: 2px solid var(--color-ascent);
}

.Active {
  border-bottom: 2px solid var(--color-ascent);
}

.Content {
  flex-grow: 1;
  /* padding: 20px; */
}

@media only screen and (max-width: 1025px) {
  .Navigation {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
  .Step {
    width: unset;
    font-size: 0.85rem;
  }
}
