@import '../../vars.css');

.Accordian {
  width: 100%;
}

.Title {
  box-sizing: border-box;
  padding: 1rem 2rem;
  background-color: var(--color-light);
  width: inherit;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  font-weight: 600;
  border-top: 1px solid #7f8c8d;
  border-bottom: 1px solid #7f8c8d;
}

.Content {
  box-sizing: border-box;
  padding: 1rem 2rem;
  background-color: #f9f9f9;
  width: inherit;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  font-weight: 300;
}
