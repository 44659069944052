.Gridrow {
  display: grid;
  width: 100%;
  min-height: 500px;
  overflow: hidden;
}
.Box {
  position: relative;
  overflow: hidden;
}
.BoxImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.Content {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

.Gradient {
  background: radial-gradient(#ffffff00, #0f060693);
  background-color: rgba(0, 0, 0, 0.1);
}

.ContentBody {
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  /* gap: 20px; */
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
}

.ContentBody *:not(Button, Button *) {
  color: var(--color-secondary);
}
.ContentBodyTitle {
  font-size: 3rem;
  font-weight: 700;
  line-height: 3rem;
}
.ContentBody p {
  font-weight: 200;
  font-size: 1rem;
  /* padding: 10px; */
  box-sizing: border-box;
}

.ContentBody table {
  font-weight: 500;
  box-sizing: border-box;
}

.ContentBody td {
  padding: 0.5rem 1rem;
}

.ContentBody tr {
  display: inline-flex;
  flex-direction: column;
}

.ScrollableFull {
  display: grid;
  grid-template-columns: 1fr;
  gap: 75px;
  padding: 75px 200px;
}

.Scrollable {
  display: grid;
  grid-template-columns: 1fr;
  gap: 75px;
}

.Scrollable > *,
.ScrollableFull > * {
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 1025px) {
  .Gridrow:nth-of-type(2n + 1) {
    flex-direction: column-reverse;
  }
  .Gridrow {
    display: flex;
    flex-direction: column;

    height: unset !important;
  }
  .Content {
    min-height: 500px;
  }
  .Box {
    overflow: unset;
  }
  .ScrollableFull {
    padding: 1rem;
  }
}

@media screen and (min-width: 1025px) {
  .ContentBody tr {
    display: grid;
  }
  .ContentBody table {
    padding: 1rem;
  }
  .ContentBody td {
    padding: 0.5rem 1rem;
  }
  .ContentBody {
    padding: 2rem 3rem;
    height: 100%;
    justify-content: space-around;
  }
  .ContentBodyTitle {
    font-size: 3rem;
    font-weight: 500;
    line-height: 4rem;
  }
}
