@import '../../vars.css';

.Register {
  /* overflow-y: scroll; */
  scroll-snap-type: y mandatory;

  /* width: 100vw; */

  /* scroll- */
}

.Options {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  min-height: 100vh;
  padding: 1rem;
  background-color: var(--color-light);
}
.Options > p {
  /* width: 1000px; */
  text-align: center;
  font-size: 15px;
}
.Waivers {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.Register > .Options > a > img {
  width: 250px;
}
.Fullscreen {
  min-height: 100vh;

  scroll-snap-align: start;
}

.Info {
  height: 100%;
  width: 100%;
  display: grid;
  place-content: center;
  place-items: center;
  font-size: 10px;
  gap: 10px;
}

.Icons {
  height: 100%;
  width: 100%;
  display: grid;
  place-content: center;
  place-items: center;
  gap: 10px;
}
.Icons * {
  color: var(--color-ascent) !important;
}

.Video {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  padding: 1rem;
}

.Video h2 {
  font-size: 36px;
  text-align: center;
}

.Video p {
  color: var(--color-primary);
  cursor: pointer;
}

.Link {
  display: grid;
  place-content: center;
  background-color: var(--color-light);

  place-items: center;
}

@media only screen and (max-width: 1025px) {
  .Video iframe {
    width: 100% !important;
  }
}

@media only screen and (min-width: 1025px) {
  .Info {
    font-size: 16px;
  }

  .Options > p {
    width: 1000px;
    text-align: center;
    font-size: 20px;
  }
  .Fullscreen {
    height: 100vh;
  }
  .Options {
    min-height: unset;
  }
  .Waivers {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 75px;
  }
}
