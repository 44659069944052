.Links {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-evenly;
  width: 100%;
  padding: 20px 20px;
}
.Links > a {
  flex: 1;
  text-align: center;
  padding: 1rem;
  /* border: 4px solid black; */
  color: var(--color-ascent);
  background-color: white;
  font-size: 1.25rem;
  font-weight: 500;
  text-decoration: none;
  border-radius: 30px;
}
.Links > a:hover {
  background-color: var(--color-ascent);
  color: white;
  transition: 0.2s;
  cursor: pointer;
}
@media only screen and (max-width: 1025px) {
  .Links {
    padding: 1rem;
  }
  .Links a {
    font-size: 0.75rem;
  }
}
