@import './vars.css';
.primaryText {
  color: var(--color-primary);
}

.secondaryText {
  color: var(--color-secondary);
}

.accentText {
  color: var(--color-light);
}

.whiteText {
  color: white;
}
