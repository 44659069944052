.Angledblock {
  position: relative;
}

.BeforeShape {
  position: absolute;
  width: 100%;
  bottom: calc(100%);
}
.BeforeShape img {
  width: 100%;
}

.MainBlock {
  position: relative;
}
.AfterShape {
  position: relative;
  width: 100%;
  top: calc(100%);
}
.AfterShape img {
  width: 100%;
}

.Angledblock img {
  max-width: unset;
}

@media only screen and (min-width: 1025px) {
}
