@import './vars.css';

* {
  padding: 0;
  margin: 0;
  color: var(--color-secondary);
  font-family: titillium;
}
html {
  height: 100%;
}
body {
  height: 100%;
}
#root {
  height: 100%;
}

@font-face {
  font-family: 'Titillium';
  src: url('./assets/fonts/Titillium_Web/TitilliumWeb-Light.ttf')
    format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Titillium';
  src: url('./assets/fonts/Titillium_Web/TitilliumWeb-Regular.ttf')
    format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Titillium';
  src: url('./assets/fonts/Titillium_Web/TitilliumWeb-SemiBold.ttf')
    format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Titillium';
  src: url('./assets/fonts/Titillium_Web/TitilliumWeb-Bold.ttf')
    format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Titillium';
  src: url('./assets/fonts/Titillium_Web/TitilliumWeb-Black.ttf')
    format('truetype');
  font-weight: 800;
  font-style: normal;
}
