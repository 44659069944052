@import url('../../vars.css');
/* .Faq {
} */

.Slogan {
  font-size: 1.5rem;
  width: 100%;
  line-height: 5rem;
  text-shadow: 0px 2px 5px #00000043;
  text-align: center;
}

.LandingContent {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 100%;
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
  align-items: center;
}

.Content {
  padding: 50px 1rem;
}

@media only screen and (min-width: 1025px) {
  .Slogan {
    font-size: 2.5rem;
  }
  .LandingContent {
    padding: 0 10rem;
  }
  .Content {
    padding: 5rem 200px;
  }
}
