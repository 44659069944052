@import url(../../vars.css);

.Hooked {
}
/* 
.Content {
  padding: 50px 1rem;
  display: flex;
  flex-direction: column;

  gap: 50px;
} */

.Slogan {
  font-size: 2rem;
  width: 100%;
  line-height: 4rem;
  text-shadow: 0px 2px 5px #00000043;
  padding: 1rem 3rem;
}
.Slogan li {
  color: white;
  list-style: circle;
  line-height: 30px;
}
/* .Slogan li:not(:last-child) {
  margin-bottom: 0.5rem;
} */
.LandingContent {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 100%;
  width: 100%;

  box-sizing: border-box;
  align-items: center;
}

.Panel {
  padding: 1rem;
  background-color: var(--color-light);
  border-radius: 30px;
}
.Offering {
  padding: 50px;
  display: grid;
  gap: 1rem;
}
.Offering h2 {
  font-size: 30px;
}
.Offering p {
  font-size: 15px;
}

@media only screen and (min-width: 1025px) {
  /* .Content {
    margin: 100px 150px;
  } */
  .Offering {
    padding: 100px;
  }
  .Offering h2 {
    font-size: 30px;
  }
  .Offering p {
    font-size: 20px;
  }
  .Slogan {
    font-size: 2.5rem;
    padding: unset;
  }
  .Slogan li {
    /* font-size: rem; */
    line-height: 60px;
  }
  .LandingContent {
    padding: 0 10rem;
  }
}
