.Highlight {
  box-sizing: content-box;
  padding: 5px 25px;
  border-radius: 20px;
  background-color: var(--color-ascent);
  color: white;
}

.App {
  height: 100%;
}

.Footer * {
  font-size: 0.8rem;
}

.Footer h1 {
  font-size: 1rem;
  color: var(--color-primary);
  margin: 5px 0 5px 0;
}
.Footer {
  display: flex;
  flex-direction: column;
}
