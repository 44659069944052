@import '../../vars.css';
.Flipper {
  height: 150px;
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}

.Flipper:hover .Border {
  transform: rotateY(180deg);
  /* animation-name: flip;
  animation-duration: 1s; */
}

.Border {
  position: relative;
  height: 100%;
  width: 100%;
  border: 2px solid black;
  border-radius: 15px;
  text-align: center;
  transition: transform 0.5s;
  transform-style: preserve-3d;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  /* transition: width 0.5s; */
}

.Front,
.Back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  padding: 1rem;
  box-sizing: border-box;
  background-color: var(--color-light);
  border-radius: 15px;
}

svg {
}
.Back {
  transform: rotateY(180deg);
}

@media only screen and (max-width: 1025px) {
  .Flipper {
    height: 200px !important;
    width: 200px !important;
  }
}

/* @keyframes flip {
  0% {
    width: 100%;
    color: inherit;
  }
  25% {
    color: rgba(0, 0, 0, 0);
  }
  50% {
    width: 0px;
  }
  75% {
    color: rgba(0, 0, 0, 0);
  }
  100% {
    width: 100%;
    color: inherit;
  }
} */
