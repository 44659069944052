.CoursePage {
  background-color: var(--color-light);
}
.ContentWrapper {
  padding: 1rem;
}

.Slogan {
  font-size: 2rem;
  width: 100%;
  line-height: 5rem;
  text-align: center;
  text-shadow: 0px 2px 5px #00000043;
}
.Slogan > * {
  font-weight: 500;
}

.LandingContent {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 100%;
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
  align-items: center;
}

.Course {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  align-items: flex-start;
  gap: 20px;
  justify-content: space-between;
  box-sizing: border-box;
}

.Courses {
  display: grid;
  grid-template-columns: 1fr;
  gap: 75px;
  padding: 1rem;
}

.Courses > * {
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
}

.Course *:not(Button, Button *) {
  color: var(--color-secondary);
}
.CourseTitle {
  font-size: 3rem;
  font-weight: 700;
  line-height: 3rem;
}
.Course p {
  font-weight: 200;
  font-size: 1rem;
}

/* .Links > a:first-child {
  border-right-width: 2px;
}
.Links > a:last-child {
  border-left-width: 2px;
} */

@media only screen and (min-width: 1025px) {
  .Course p {
    font-size: 1.25rem;
  }
  .Courses {
    grid-template-columns: 1fr;
    gap: 75px;
    padding: 75px 200px;
  }
  .ContentWrapper {
    padding: 0 200px;
  }
  .Far_Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .Slogan {
    font-size: 2.5rem;
  }
  .LandingContent {
    padding: 0 10rem;
  }
  .Course {
    padding: 3rem;
    width: 100%;
    height: 100%;
    gap: 30px;
    justify-content: space-between;
  }
  .CourseTitle {
    font-size: 4rem;
    font-weight: 500;
    line-height: 3rem;
  }
}
