.PriceWidget a {
  width: 100%;
}
.PriceWidget {
  position: relative;
  width: 305px;
  /* height: 300px; */
  background-color: #fff;
  border-radius: 20px;
  /* border-width: 2px; */
  /* border: 2px solid var(--color-secondary); */
  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.2));
}
.PriceWidget > h1 {
  font-size: 3rem;
}

.StepBox {
  font-size: 16px;
  padding: 20px;
  box-sizing: border-box;
}
.StepBox ul {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.StepBox li {
  list-style: none;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Accent {
  color: var(--color-ascent);
}

@media only screen and (min-width: 1025px) {
  .PriceWidget {
    min-width: 400px;
  }
}
