.Dropdown {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  display: grid;
  place-content: center;
  place-items: center;
  height: 100%;
}
.Dropdown a {
  /* padding: 0 1rem 1rem 1rem; */
}

.Submenu {
  position: relative;
  /* margin-top: 1rem; */
  min-width: 200px;
  padding: 1rem 1rem;
  flex-direction: column;
  gap: 10px;
  display: none;
  z-index: 999;
  font-size: 0.9rem;
  font-weight: 300;
}
.Submenu li {
  text-align: center;
}

.Dropdown:hover .Submenu {
  display: flex;
  align-items: center;
}

.Submenu:hover {
  display: flex;
  align-items: center;
}

.DropOption {
  width: 100%;
}
.DropOption:hover {
  background-color: #ececec;
}
@media only screen and (max-width: 1439px) {
  .Dropdown {
    height: unset;
  }
}

@media only screen and (min-width: 1440px) {
  .Dropdown a {
    padding: 1rem 0;
    /* margin-bottom: ; */
  }
  .Submenu {
    position: absolute;
    max-width: 200px;
    padding: 1rem 1rem;
    top: 90%;
    flex-direction: column;
    gap: 10px;
    background-color: white;
    border-radius: 5px;
    -webkit-box-shadow: 0px 4px 15px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 15px 3px rgba(0, 0, 0, 0.1);
    display: none;
  }
}
