.Prices {
  background-color: var(--color-light);
}

.LandingContent {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  height: 100%;
  width: 100%;
  padding: 0 200px;
  box-sizing: border-box;
  align-items: center;
}

.Slogan {
  font-size: 2rem;
  width: 100%;
  line-height: 5rem;
  text-align: center;
  text-shadow: 0px 2px 5px #00000043;
}
.Slogan > * {
  font-weight: 500;
}

@media only screen and (max-width: 1025px) {
  .LandingContent {
    padding: 0 1rem;
    flex-direction: column;
  }
}
