.Notfound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  height: 100%;
}
.Notfound img {
  width: 200px;
}
