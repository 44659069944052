@import '../../vars.css';

.Header {
  position: relative;
  background-color: var(--color-light);
  height: 75px;
  /* padding: 1rem 0rem; */
  box-sizing: border-box;
  color: var(--color-secondary);
  font-family: titillium;
  font-weight: 600;
  position: relative;
  z-index: 5;
}
.Shape > svg {
  visibility: hidden;
}
.HeaderContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 100px;
}
.Header li {
  list-style: none;
}
.Header a {
  text-decoration: none;
  color: var(--color-secondary);
}
.Brand li,
.Brand a {
  display: contents;
}

.HeaderContainer > ul {
  margin: unset;
  /* padding: unset; */
  font-size: 13px;
  height: 100%;
}

.Logo img {
  width: 150px;
  /* margin-left: 1rem; */
  position: relative;
  z-index: 10;
  /* margin-top: 1rem; */
}

.Brand {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* padding: 1rem 0; */
}
.Navigation {
  padding-top: 125px;
  height: 100vh;
  width: 100vw;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 35px;
  background-color: var(--color-light);
}

.Navigation li {
  /* padding: 0 1rem; */
}
/* .Shape
{
  cursor: pointer;
} */
.MobileClose {
  position: absolute;
  right: 1rem;
  top: calc(50% - 17.5px);
  text-align: center;
  font-size: 1.5rem;
  line-height: normal;
  line-height: 1rem;
}
.MobileClose > button {
  all: unset;
}

.hamburger {
  display: block;
  text-align: center;
}
/* svg {
  cursor: pointer;
} */
.frstbar,
.scndbar,
.thrdbar {
  transition: all 0.35s linear;
  transform: rotate(0deg);
  transform-origin: 0% 50%;
}
.burgericon.open .frstbar {
  transform: rotate(45deg);
  x: -2;
}
.burgericon.open .thrdbar {
  transform: rotate(-45deg);
  x: -2;
}
.burgericon.open .scndbar {
  width: 0;
  opacity: 0;
}
/* 
/* Tablet */
/* @media only screen and (min-width: 1025px) {
  
  .Shape > svg {
    visibility: visible;
    max-height: 125px;
    position: absolute;
    top: 0;
    z-index: -1;
  }
  .Brand {
    padding: unset;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .MobileClose {
    display: none;
  }
  .Navigation {
    padding: unset;
    flex-direction: row;
    position: relative;
    height: unset;
    width: unset;
    background-color: unset;
    height: calc(100% - 35px) !important;
  }
  .Logo img {
    margin-left: unset;
    height: unset;
    width: 235px;
  }
}  */
/* Desktop */
@media only screen and (min-width: 1440px) {
  .Header {
    height: 90px;
  }
  .Shape > svg {
    visibility: visible;
    max-height: 127px;
    position: absolute;
    top: 0;
    z-index: -1;
  }
  .HeaderContainer {
    padding: 0rem 200px;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    height: 125px;
    /* bottom: -10px; */
  }
  .Brand {
    padding: unset;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .MobileClose {
    display: none;
  }
  .Navigation {
    padding: unset;
    flex-direction: row;
    position: relative;
    height: unset;
    width: unset;
    background-color: unset;
    height: calc(100% - 35px) !important;
  }
  .Logo img {
    margin-left: unset;
    height: unset;
    width: 235px;
  }
}
