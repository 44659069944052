.Facilities {
}

Small {
  color: white;
  font-size: 1rem;
}
.Slogan {
  font-size: 1.5rem;
  width: 100%;
  line-height: 5rem;
  text-shadow: 0px 2px 5px #00000043;
  padding: 1rem 3rem;
}
.Slogan li {
  color: white;
  list-style: circle;
  line-height: 30px;
}
/* .Slogan li:not(:last-child) {
  margin-bottom: 0.5rem;
} */
.LandingContent {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 100%;
  width: 100%;

  box-sizing: border-box;
  align-items: center;
}

@media only screen and (min-width: 1025px) {
  .Slogan {
    font-size: 2.5rem;
    padding: unset;
  }
  .Slogan li {
    /* font-size: rem; */
    line-height: 60px;
  }
  .LandingContent {
    padding: 0 10rem;
  }
}
