@import '../../vars.css';

.Cover {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.Cover img {
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.Content {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.Overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}
.Mobile {
  max-height: 300px;
}

.Filter {
  background-color: #00000068;
}
.Centeroverlay {
  background-color: hsla(0, 0%, 0%, 0.3);
}

.Leftoverlay {
  background-image: linear-gradient(45deg, #0b173969, transparent);
}
.Rightoverlay {
  background-image: linear-gradient(320deg, #0b173969, transparent);
}

@media only screen and (min-width: 1025px) {
}

@media only screen and (max-width: 1025px) {
  .Cover {
    height: 100vh !important;
  }
}
