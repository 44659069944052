.Home {
  background-color: var(--color-light);
}
.WhiteHeader {
  color: white;
  font-size: 3rem;
  text-align: center;
}

.PunchlineText {
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 47px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 47px */
}

.AccompanyText {
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 25px;
  font-style: italic;
  font-weight: 700;
  line-height: 100%; /* 25px */
}
.Reviewables {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 100px;
  width: 100%;
  padding: 20px;
}

.CourseGrid {
  padding: 100px 200px;
  box-sizing: content-box;
  display: grid;
  height: 550px;
  grid-template-areas: 'FirstTime Adults Adults' 'FirstTime Kids Improvers';
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 2fr;
  gap: 35px;
}

.CourseFloat {
  border-radius: 35px;
  /* border: 2px solid var(--NCC-Accent, #393e41); */
  padding: 20px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.CourseFloat h2 {
  color: white;
  font-weight: 800;
  font-size: 1.75rem;
  letter-spacing: 1.6px;
}

.CourseGrid .FirstTime {
  grid-area: FirstTime;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    no-repeat center/cover url('/src/assets/images/home/first_time.jpg'), #fff;
}
.CourseGrid .Adults {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    no-repeat center/cover url('/src/assets/images/home/adults.jpg'), #fff;
  grid-area: Adults;
}
.CourseGrid .Kids {
  grid-area: Kids;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    no-repeat center/cover url('/src/assets/images/home/kids.jpg'), #fff;
}
.CourseGrid .Improvers {
  grid-area: Improvers;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    no-repeat center/cover url('/src/assets/images/home/improvers.jpg'), #fff;
}

.NewCustomerNavigation {
  display: inline-flex;
  padding: 15px;
  align-items: flex-start;
  gap: 15px;
  border-radius: 20px;
  /* border: 2px solid var(--color-secondary); */
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
}
.GoogleWidget {
  min-height: 119px;
  min-width: 157px;
}

.Slogan {
  font-size: 2.5rem;
  line-height: 5rem;
  text-align: left;
  width: 100%;
  text-shadow: 0px 2px 5px #00000043;
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: flex-start;
}
/* .Slogan > * {
  align-self: baseline;
} */

.LandingContent {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  height: 100%;
  width: 100%;
  padding: 0 200px;
  box-sizing: border-box;
  align-items: center;
}

.FacilitiesWrapper {
  margin: 100px 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
}
.Facilities {
  display: flex;
  flex-direction: row;
  gap: 75px;
}

.Facilities > img {
  width: 500px;
}
.Facilities ul {
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
  gap: 0.3rem;
}

@media only screen and (max-width: 1025px) {
  .Reviewables {
    gap: 50px;
    flex-direction: column;
    padding: 2rem;
  }
  .LandingContent {
    flex-direction: column;
    padding: 1rem;
    height: 100%;
    gap: 1rem;
    align-items: center;
    justify-content: center;
  }
  .Slogan {
    font-size: 1.25rem;
    line-height: 3.5rem;
    text-align: center;
    align-items: center;
  }
  .CourseGrid {
    padding: 50px 1rem;
    display: flex;
    flex-direction: column;

    gap: 35px;
  }
  .FacilitiesWrapper {
    padding: 50px 1rem;
    margin: unset;
  }
  .Facilities {
    flex-direction: column;
    gap: 50px;
  }
}
